import styled from 'styled-components';
import React from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../interfaces';
import slideRight from '../../Terabill/Animations/slideRight';
import { applicationActions } from '../../../store/actions';

function SideNavigationComponent() {
  const { navigationOpen } = useSelector((state: RootState) => state.application);
  const dispatch = useDispatch();
  return (
    <>
      <MenuButton onClick={() => dispatch(applicationActions.setNavigationOpen(!navigationOpen))} className="toggle-sidebar">
        <FontAwesomeIcon icon={faBars} scale="5x" focusable />
      </MenuButton>
      {navigationOpen
        && (
        <SideNavMenu className="side-nav">
          <NavButton href="/home">Home</NavButton>
          <NavButton href="/solutions">Solutions</NavButton>
          <NavButton href="/services">Services</NavButton>
          <NavButton href="/about">About</NavButton>
        </SideNavMenu>

        )}
    </>
  );
}
const NavButton = styled.a`
  padding-left: 15px;
  color: black;
  font-size: 16px;
  height: 50px;
  width: 100%;
  font-weight: 550;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border-bottom: 1px solid white;

  &:hover {
    background-color: white;
  }
`;

const MenuButton = styled.a`
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
  .fa-bars {
    width: 100%;
    font-size: 32px;
  }
`;

const SideNavMenu = styled.div`
  position: fixed;
  background-color: rgba(232, 239, 247, 1);
  top: 100px;
  height: 100%;
  bottom: 0;
  left: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: ${slideRight} .5s linear;
`;

const SideNavigation = styled(SideNavigationComponent)`
    z-index: 3;
`;

export default SideNavigation;
