import styled from 'styled-components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import colorMap from '../colorSolver.json';
import SubHeader from '../SubHeader';

interface QuoteBlockProps {
    className?: string;
    color: string;
    mainText: string;
    subText: string;
    headerText: string;
}

function QuoteBlock({
  className, mainText, subText, headerText, color,
}: QuoteBlockProps) {
  // @ts-ignore
  const { mainTextColor, subTextColor, backgroundColor } = colorMap[color];
  return (
    <QuoteBlockDiv color={backgroundColor} className={className}>
      {headerText
                && <SubHeader text={headerText} />}
      {mainText
                && (
                <>
                  <FontAwesomeIcon icon={faQuoteLeft} size="2x" />
                  <MainText color={mainTextColor}>
                    {mainText}
                  </MainText>
                  <FontAwesomeIcon icon={faQuoteRight} size="2x" />
                </>
                )}
      {subText
                && (
                <SubText color={subTextColor}>
                  {subText}
                </SubText>
                )}
    </QuoteBlockDiv>
  );
}

interface TextProps {
    color: string;
}

interface MainTextProps {
    color: string;
}

interface SubTextProps {
    color: string;
}

const QuoteBlockDiv = styled.div<TextProps>`
  background-color: ${(props) => props.color};
  white-space: pre-line;
  padding: 30px 10% 30px 15%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MainText = styled.h1<MainTextProps>`
    color: ${(props) => props.color};
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    width: 100%;
`;

const SubText = styled.p<SubTextProps>`
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  width: 100%;
  color: #3b3e49ad;
  text-align: left;
  font-weight: 600;
`;

export default QuoteBlock;
