import styled from 'styled-components';
import React from 'react';
import Navigation from './Navigation';
import SideNavigation from './SideNavigation';
import useNavigation from '../../../hooks/useNavigation';

interface HeaderProps {
    className?: string;
}

function HeaderComponent({ className }: HeaderProps) {
  const { navigateToPage } = useNavigation();
  return (
    <div className={className}>
      <SideNavigation />
      <Image src="./images/logo.gif" alt="none.jpg" onClick={() => navigateToPage('home')} />
      <Navigation className="nav-block" />
    </div>
  );
}

const Image = styled.img`
    height: 50px;
    padding-left: 10px;
  
  :hover {
    cursor: pointer;
  }
`;

const Header = styled(HeaderComponent)`
    top: 0;
    z-index: 10;
    position: fixed;
    width: 100%;
    height: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    box-shadow: rgb(0 0 0 / 25%) 0 1px;
    background-color: white;
`;

export default Header;
