import { Keyframes, keyframes } from 'styled-components';

export default (): Keyframes => keyframes`

  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }

`;
