import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { applicationActions } from '../store/actions';

export default (): void => {
  const dispatch = useDispatch();

  const updateSize = useCallback(() => {
    dispatch(applicationActions.setIsMobile(window.innerWidth < 1024));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);
};
