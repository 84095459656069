import { AnyAction } from 'redux';
import { ApplicationState } from '../../interfaces';
import { ApplicationActionTypes } from '../actionTypes';

const initialState: ApplicationState = {
  isMobile: false,
  page: '',
  navigationOpen: false,
  isPageFullHeight: false,
};

export default (
  state = initialState,
  { type, payload }: AnyAction = { type: ApplicationActionTypes.DEFAULT },
): ApplicationState => {
  switch (type) {
    case ApplicationActionTypes.SET_MOBILE:
      return {
        ...state,
        isMobile: payload,
      };
    case ApplicationActionTypes.SET_PAGE:
      return {
        ...state,
        page: payload,
      };
    case ApplicationActionTypes.SET_PAGE_IS_FULL_HEIGHT:
      return {
        ...state,
        isPageFullHeight: payload,
      };
    case ApplicationActionTypes.SET_NAV_OPEN:
      return {
        ...state,
        navigationOpen: payload,
      };
    default:
      return state;
  }
};
