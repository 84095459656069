import {
  applyMiddleware, compose, createStore,
} from 'redux';
import reduxThunk from 'redux-thunk';
import { PartialRootState } from '../interfaces';
import rootReducer from './reducers';
import { promiseMiddleware } from './middleware';

const store = (initialState?: PartialRootState) => {
  const middleware = applyMiddleware(
    reduxThunk,
    promiseMiddleware,
  );

  let composeEnhancers = compose;
  if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;
    // eslint-disable-next-line global-require
  }

  return createStore(rootReducer, initialState, composeEnhancers(middleware));
};

export default store;
