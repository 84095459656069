import { useCallback } from 'react';

const useNavigation = () => {
  const navigateToPage = useCallback((page?: string): void => {
    // Check if we are navigating to the same page or from a blocked page
    if (!page) {
      return;
    }
    window.location.href = `./${page}`;
  }, []);

  return {
    navigateToPage,
  };
};

export default useNavigation;
