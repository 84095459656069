import styled from 'styled-components';
import React from 'react';

interface BannerButtonProps {
    className?: string;
    title: string;
    text: string;
}

function BannerButtonComponent({
  className, title, text,
}: BannerButtonProps) {
  return (
    <div className={className}>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </div>
  );
}

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: black;
  margin-bottom: 21px;
  font-family: "Sarabun",sans-serif;
  text-align: center;
`;

const Text = styled.p`
  margin-bottom: 36px;
  font-size: 16px;
  transition: all .2s ease-out 0s;
  font-family: "Poppins",sans-serif;
  text-align: center;
  color: #57667e;
`;

const BannerButton = styled(BannerButtonComponent)`
  
  width: 200px;
  background-color: white;
  border-radius: 5px;
  border: 8px solid transparent;
  padding: 30px 22px;
  display: flex;
  flex-direction: column;
  transition: all .5s ease-in;
  text-decoration: none;

  &:hover {
    background-color: rgba(232, 239, 247, .8);
  }
`;

export default BannerButton;
