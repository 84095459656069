import { useEffect, useState } from 'react';
import localeData from '../locale/index';

const useLocale = (): {locale: string, messages: Record<string, string>} => {
  const [locale, setLocale] = useState<string>('en-US');
  const [messages, setMessages] = useState<Record<string, string>>({});

  useEffect(() => {
    if (locale === 'ee') {
      setLocale('ee');
    }
    setMessages(localeData[locale]);
  }, [locale]);
  return { locale, messages };
};

export default useLocale;
