import styled from 'styled-components';
import React from 'react';
import Header from './Header';
import Footer from './Footer';

interface HomePageProps {
    className?: string;
    children: any;
}

function LayoutComponent({ className, children }: HomePageProps) {
  return (
    <div className={className}>
      <Header className="header" />
      {children}
      <Footer className="footer" />
    </div>
  );
}

const Layout = styled(LayoutComponent)`
    position: absolute;
    max-height: 100%;
    height: 100vh;
    width: 100%;
    z-index: 3;
`;

export default Layout;
