import styled from 'styled-components';
import React from 'react';
import colorMap from '../colorSolver.json';
import SubHeader from '../SubHeader';

interface ImageBlockProps {
    className?: string;
    color: string;
    headerText: string;
    imageName?: string;
}

function ImageBlock({
  className, headerText, color, imageName,
}: ImageBlockProps) {
  // @ts-ignore
  const { backgroundColor } = colorMap[color];

  return (
    <ImageBlockDiv color={backgroundColor} className={className}>
      {headerText
        && <SubHeader text={headerText} />}
      {imageName
        && (
        <ImageWrapper>
          <Image src={`./images/${imageName}`} />
        </ImageWrapper>
        )}
    </ImageBlockDiv>
  );
}

interface ImageProps {
    color: string;
}

const ImageWrapper = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Image = styled.img``;

const ImageBlockDiv = styled.div<ImageProps>`
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  padding: 30px 10% 30px 15%;
  min-height: 200px;
`;

export default ImageBlock;
