import styled from 'styled-components';
import React from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BannerButton from './BannerButton';

interface BannerProps {
  className?: string;
}

function BannerComponent({ className }: BannerProps) {
  return (
    <div className={className}>
      <InnerWrapper>
        <MainText>Bill more with less</MainText>
        <SubText>
          We provide automated Billing and Customer care solution terabill.com
          to telecom companies:
          <br />
          <br />
          • Starting telecoms, carriers and internet service providers
          <br />
          • Active and growing telecom companies
          <br />
          • Ones planning to launch voice or the other volume based services
        </SubText>
        <ButtonWrapper className="button-wrapper">
          <BannerButton
            title="Starting"
            text="Launch and start billing your services at the speed of marketing. Lower entry barriers with SaaS"
          />
          <BannerButton
            title="Growing"
            text="Break the limits in your existing billing system"
          />
          <BannerButton
            title="Experienced"
            text="Evaluate new billing and customer care system while redesigning your processes, both internal  and ones with external partners"
          />
        </ButtonWrapper>
        <BottomButtonContainer>
          <BottomButtonWrapper href="./solutions">
            <BottomButtonText>Solutions</BottomButtonText>
            <FontAwesomeIcon icon={faArrowRight} scale="1x" focusable />
          </BottomButtonWrapper>
        </BottomButtonContainer>
      </InnerWrapper>
    </div>
  );
}

const InnerWrapper = styled.div`
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding-left: 15%;
    padding-right: 10%;
    width: 100%;
`;

const BottomButtonText = styled.span`
    font-family: "Poppins",sans-serif;
    font-size: 20px;
    text-decoration: underline;
    margin-right: 5px;
`;

const BottomButtonWrapper = styled.a`
    margin-bottom: 5px;
    color: rgb(219 218 216);
    cursor: pointer;
    text-decoration: none;
    
    &:hover {
      color: white;
    }
`;

const BottomButtonContainer = styled.div`
  
    margin-top: 10px;
    @media(min-height: 800px) {
      margin-top: 40px;
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    @media(max-height: 1200px) {
      height: 250px;
    }
`;

const MainText = styled.h1`
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    width: 100%;
`;
const SubText = styled.p`
  background-color: rgb(0 6 12 / 50%);
  color: white;
  border-radius: 5px;
  border: 8px solid transparent;
  font-family: "Sarabun",sans-serif;
  font-weight: 500;
  
  font-size: 18px;
  @media(max-height: 150px) {
    font-size: 25px;
  }
  width: 100%;
  transition: all .5s ease-in;

  &:hover {
    background-color: rgb(0 6 12 / 20%);
  }
`;

const Banner = styled(BannerComponent)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: url("./images/banner.jpg") no-repeat;
    background-size: cover;
    height: 100%;
    padding-top: 100px;
`;

export default Banner;
