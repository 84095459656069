import { AxiosError, AxiosResponse } from 'axios';
import { Middleware } from 'redux';
import { RootState } from '../../interfaces';
import { httpClient } from '../../services';

const promiseMiddleware: Middleware<{}, RootState> = () => (next) => {
  const formatTypeName = (type: string): string => type.split('_REQUEST').join('');

  return (action): void => {
    const { useApi, type, ...rest } = action;

    next({ ...rest, type });

    const onSuccess = ({ data, ...meta }: AxiosResponse): void => {
      const successType = `${formatTypeName(type)}_SUCCESS`;

      next({
        ...rest,
        payload: data,
        meta,
        type: successType,
      });
    };

    const onError = (error: AxiosError): void => {
      const failedType = `${formatTypeName(type)}_FAILURE`;

      next({ ...rest, error, type: failedType });
    };

    if (useApi) {
      useApi(httpClient).then(onSuccess, onError);
    }
  };
};

export default promiseMiddleware;
