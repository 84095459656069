import styled from 'styled-components';
import React from 'react';
import servicesConf from './servicesConf.json';
import TextBlock from '../Terabill/TextBlock/TextBlock';
import ImageBlock from '../Terabill/ImageBlock/ImageBlock';

interface ServicesPageProps {
  className?: string;
}

function ServicesPageComponent({ className }: ServicesPageProps) {
  return (
    <div className={className}>
      {
        servicesConf.map((section) => {
          switch (section.blockType) {
            case 'text':
              return (
                <TextBlock
                  key={section.headerText}
                  mainText={section.mainText}
                  subText={section.subText}
                  headerText={section.headerText}
                  color={section.color}
                />
              );
            case 'image':
              return (
                <ImageBlock
                  key={section.headerText}
                  headerText={section.headerText}
                  imageName={section.imageName}
                  color={section.color}
                />
              );
            default:
              return (<div className="empty-block" />);
          }
        })
      }
    </div>
  );
}

const ServicesPage = styled(ServicesPageComponent)`
    width: 100%;
    z-index: 1;
  
    padding-top: 100px;
`;

export default ServicesPage;
