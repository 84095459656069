import styled from 'styled-components';
import React from 'react';
import aboutConf from './aboutConf.json';
import TextBlock from '../Terabill/TextBlock/TextBlock';
import QuoteBlock from '../Terabill/TextBlock/QuoteBlock';

interface AboutProps {
    className?: string;
}

function AboutComponent({ className }: AboutProps) {
  return (
    <div className={className}>
      {
            aboutConf.map((section) => {
              switch (section.blockType) {
                case 'text':
                  return (
                    <TextBlock
                      key={section.headerText}
                      mainText={section.mainText}
                      subText={section.subText}
                      headerText={section.headerText}
                      color={section.color}
                    />
                  );
                case 'quote':
                  return (
                    <QuoteBlock
                      key={section.headerText}
                      mainText={section.mainText}
                      subText={section.subText}
                      headerText={section.headerText}
                      color={section.color}
                    />
                  );
                default:
                  return (<div className="empty-block" />);
              }
            })
}
    </div>
  );
}

const AboutPage = styled(AboutComponent)`
  width: 100%;
  z-index: 1;
  padding-top: 100px;
`;

export default AboutPage;
