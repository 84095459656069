import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Banner from './Banner';
import { applicationActions } from '../../store/actions';

interface HomePageProps {
    className?: string;
}

function HomepageComponent({ className }: HomePageProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(applicationActions.setIsPageFullHeight(true));
    return () => {
      dispatch(applicationActions.setIsPageFullHeight(false));
    };
  });

  return (
    <div className={className}>
      <Banner className="banner" />
    </div>
  );
}

const HomePage = styled(HomepageComponent)`
    z-index: 1;
    overflow-y: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export default HomePage;
