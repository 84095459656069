import styled from 'styled-components';
import React from 'react';
import solutionsConf from './solutionsConf.json';
import TextBlock from '../Terabill/TextBlock/TextBlock';

interface SolutionsPageProps {
  className?: string;
}

function SolutionsPageComponent({ className }: SolutionsPageProps) {
  return (
    <div className={className}>
      {
        solutionsConf.map((section) => (
          <TextBlock
            key={section.headerText}
            mainText={section.mainText}
            subText={section.subText}
            headerText={section.headerText}
            color={section.color}
          />
        ))
      }
    </div>
  );
}

const SolutionsPage = styled(SolutionsPageComponent)`
    width: 100%;
    padding-top: 100px;
    z-index: 1;
`;

export default SolutionsPage;
