import { createGlobalStyle } from 'styled-components';

// stylelint-disable max-nesting-depth

const GlobalStyle = createGlobalStyle`
  
  html {
    min-height: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    min-height: 100%;
  }

  #root {
    height: 100%;
    width: 100%;
    min-height: 100%;
  }
`;

export default GlobalStyle;
