/* eslint-disable import/prefer-default-export */
import { AnyAction } from 'redux';
import { ApplicationActionTypes } from '../actionTypes';

export const setIsMobile = (payload: boolean): AnyAction => ({
  type: ApplicationActionTypes.SET_MOBILE,
  payload,
});

export const setPage = (payload: string): AnyAction => ({
  type: ApplicationActionTypes.SET_PAGE,
  payload,
});

export const setIsPageFullHeight = (payload: boolean): AnyAction => ({
  type: ApplicationActionTypes.SET_PAGE_IS_FULL_HEIGHT,
  payload,
});

export const setNavigationOpen = (payload: boolean): AnyAction => ({
  type: ApplicationActionTypes.SET_NAV_OPEN,
  payload,
});
