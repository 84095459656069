import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../interfaces';

interface SubHeaderProps {
    className?: string;
    text: string;
}

function HeaderComponent({ className, text }: SubHeaderProps) {
  const { navigationOpen } = useSelector((state: RootState) => state.application);
  return (
    <HeaderSpan className={className} navOpen={navigationOpen}>
      {text}
    </HeaderSpan>
  );
}

interface HeaderProps {
    navOpen: boolean;
}

const Header = React.memo(HeaderComponent);

const HeaderSpan = styled.span<HeaderProps>`
  font-size: 18px;
  font-family: "Roboto",sans-serif;
  color: #eb566c;
  display: inline-flex;
  
  
  &::before {
    content: "";
    width: 70px;
    height: 2px;
    background: #eb566c;
    margin-top: 10px;
    transform: translateY(-50%);
    line-height: 20px;
    margin-right: 3px;
    z-index: ${(props) => (props.navOpen ? -1 : 1)}
  }
`;

export default Header;
