import styled from 'styled-components';
import React from 'react';

interface NavigationProps {
    className?: string;
}

function NavigationComponent({ className } : NavigationProps) {
  return (
    <div className={className}>
      <NavButton href="./home">Home</NavButton>
      <NavButton href="./solutions">Solutions</NavButton>
      <NavButton href="./services">Services</NavButton>
      <NavButton href="./about">About</NavButton>
    </div>
  );
}

const NavButton = styled.a`
  color: #14435c;
  font-size: 16px;
  height: 100%;
  width: 100%;
  font-weight: 500;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(232, 239, 247, .8);
  }
`;

const Navigation = styled(NavigationComponent)`
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding-left: 30%;
    padding-right: 30px;
`;

export default Navigation;
