import { IntlProvider } from 'react-intl';
import React from 'react';
import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';
import useLocale from './hooks/useLocale';
import GlobalHooksContainer from './components/GlobalHooks';
import HomePage from './components/HomePage/HomePage';
import GlobalStyle from './components/Terabill/GlobalStyle';
import Layout from './components/Layout/Desktop/Layout';
import SolutionsPage from './components/SolutionsPage/SolutionsPage';
import AboutPage from './components/AboutPage/AboutPage';
import ServicesPage from './components/ServicesPage/ServicesPage';

function App() {
  const { locale, messages } = useLocale();

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={locale}
      messages={messages}
    >
      <GlobalHooksContainer />
      <GlobalStyle />
      <Layout className="layout">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="/home" element={<HomePage className="page home-page" />} />
            <Route path="/solutions" element={<SolutionsPage className="page solutions-page" />} />
            <Route path="/about" element={<AboutPage className="page about-page" />} />
            <Route path="/services" element={<ServicesPage className="page services-page" />} />
          </Routes>
        </BrowserRouter>
      </Layout>
    </IntlProvider>
  );
}

export default App;
