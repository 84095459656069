import styled from 'styled-components';
import React from 'react';
import colorMap from '../colorSolver.json';
import SubHeader from '../SubHeader';

interface TextBlockProps {
    className?: string;
    color: string;
    mainText?: string;
    subText?: string;
    headerText: string;
}

function TextBlock({
  className, mainText, subText, headerText, color,
}: TextBlockProps) {
  // @ts-ignore
  const { mainTextColor, subTextColor, backgroundColor } = colorMap[color];
  return (
    <TextBlockDiv color={backgroundColor} className={className}>
      {headerText
        && <SubHeader text={headerText} />}
      {mainText
          && (
          <MainText color={mainTextColor}>
            {mainText}
          </MainText>
          )}
      {subText
            && (
            <SubText color={subTextColor}>
              {subText}
            </SubText>
            )}
    </TextBlockDiv>
  );
}

interface TextProps {
    color: string;
}

interface MainTextProps {
    color: string;
}

interface SubTextProps {
    color: string;
}

const TextBlockDiv = styled.div<TextProps>`
  background-color: ${(props) => props.color};
  white-space: pre-line;
  padding: 30px 10% 30px 15%;
  min-height: 200px;
`;

const MainText = styled.h1<MainTextProps>`
    color: ${(props) => props.color};
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    width: 100%;
`;

const SubText = styled.p<SubTextProps>`
  color: ${(props) => props.color};
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  width: 100%;
`;

export default TextBlock;
