import styled, { css } from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../interfaces';

interface FooterProps {
    className?: string;
}

function Footer({ className }: FooterProps) {
  const isPageFullHeight = useSelector((state: RootState) => state.application.isPageFullHeight);
  return (
    <FooterDiv className={className} positionAbsolute={isPageFullHeight}>
      <Text>Terabill Ltd</Text>
      <Text>info@terabill.com</Text>
      <Text>www.terabill.com</Text>
    </FooterDiv>
  );
}

const Text = styled.b`
    padding: 0 20px;
`;

const FooterDiv = styled.div.attrs((props: {positionAbsolute: boolean }) => props)`
  ${({ positionAbsolute }) => positionAbsolute && css`
    position: absolute;
    bottom: 0;
  `};
  
  width: 100%;
  height: 80px;
  display: inline-flex;
  align-items: center;
  color: rgba(211, 211, 211, 0.94);
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
  background-image: url("./images/footer.jpg");
`;

export default Footer;
